<!-- 厂家供货表 -->
<template>
  <div class="pagePadding">
    <span class="pageBtn finger btnReset marginBotom20" @click="back">返回</span>
    <br />
    <span class="fontStyle"> 客户名称：{{ cusName }} </span>
    <Table :productList="listColumns" :productData="listData" border class="table"></Table>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <!-- <Page v-if="total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="pages.page" @on-change="changePage" class="fl page" /> -->
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'OrderEqualRecordDetail',
  components: {
    Table,
  },
  data() {
    return {
      cusName: '',
      options: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '物料编码',
          key: 'matnr',
          align: 'center',
          width: 241,
        },
        {
          title: '物料描述',
          key: 'maktx',
          align: 'center',
          width: 546,
        },
        {
          title: '规格型号',
          key: 'ggxh',
          align: 'center',
          width: 200,
        },
        {
          title: '生产厂家',
          key: 'sccj',
          align: 'center',
          width: 282,
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'span',
          //       {
          //         class: 'tableBlakcFont',
          //       },
          //       '¥' + param.row.purchase_amount
          //     ),
          //   ])
          // },
        },
        {
          title: '数量',
          key: 'menge',
          align: 'center',
        },
        {
          title: '订单单位',
          key: 'meins',
          align: 'center',
        },
        {
          title: '单价',
          key: 'netpr',
          align: 'center',
          // render: (h, param) => {
          //   return h('div', [
          //     h(
          //       'span',
          //       {
          //         class: 'tableBlakcFont',
          //       },
          //       '¥' + param.row.sale_amount
          //     ),
          //   ])
          // },
        },
      ],
      // 查询参数
      queryFrom: {
        warehouse_id: '',
        factory_id: '',
        product_id: '',
        begin_time: '',
        end_time: '',
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 1,
      ebeln: null,
    }
  },
  mounted() {
    this.ebeln = this.$route.query.ebeln
    this.getOrderRecordDetailList()
    this.cusName = this.$route.query.cusName
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 点击查询
    query() {
      this.getOrderRecordDetailList()
    },
    // 页码改变
    changePage(e) {
      this.pages.page = e
      this.getOrderRecordDetailList()
    },
    // 获取厂家供货表
    getOrderRecordDetailList() {
      let data = {
        // page: this.pages.page, // 页
        // rows: this.pages.rows, // 行
        ebeln: this.ebeln,
      }
      this.$http.get(this.$api.orderSynRecordDetail, data, true).then(res => {
        this.listData = res.data
        this.total = res.data.length
      })
    },
  },
}
</script>

<style scoped lang="less">
.marginBotom20 {
  margin-bottom: 20px;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  margin-top: 20px;
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.fontStyle {
  color: #000;
  font-weight: 500;
  font-size: 20px !important;
}
</style>
